<div>
    <div class="custom-datatable">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col" *ngIf="selectable">
                        <input type="checkbox" class="checkbox_animated" [checked]="isSelectedAll()"
                            style="margin-bottom: 0;" (change)="checkAll()" />
                    </th>
                    <th scope="col" *ngFor="let column of config.columns" sortable="{{column.sortColumn}}"
                        (sort)="onSortClick($event)">
                        {{ column.label }}
                    </th>
                </tr>
            </thead>
            <tbody>

                <tr *ngFor="let item of tableData" (click)="clicked(item)" style="cursor: pointer;">
                    <td *ngIf="selectable" (click)=" $event.stopPropagation();">
                        <input type="checkbox" class="checkbox_animated" [checked]="isSelected(item)"
                            (change)="checkItem(item)" />
                    </td>
                    <td *ngFor="let column of config.columns">
                        <span [ngSwitch]="column.type">
                            <span *ngSwitchCase="'date'">
                                {{ getNestedProperty(item, column.key) | date: 'dd/MM/yyyy'}}
                            </span>
                            <span *ngSwitchCase="'dateAgo'">
                                {{ getNestedProperty(item, column.key) | dateAgo}}
                            </span>
                            <span *ngSwitchCase="'companyRole'">
                                {{ getNestedProperty(item, column.key) | companyRole }}
                            </span>
                            <span *ngSwitchCase="'button'">
                                <button class="btn btn-primary" style="color: #FFF;"
                                    (click)="buttonClicked(column, item); $event.stopPropagation();">{{column.buttonText}}</button>
                            </span>
                            <span *ngSwitchCase="'iconButton'">
                                <button class="btn btn-primary" style="color: #FFF;"
                                    (click)="buttonClicked(column, item); $event.stopPropagation();"><i
                                        class="{{column.iconClass}}"></i></button>
                            </span>
                            <span *ngSwitchCase="'yesNo'">
                                {{ getNestedProperty(item, column.key) ? 'Yes' : 'No'}}
                            </span>
                            <span *ngSwitchDefault>
                                {{ getNestedProperty(item, column.key) }}
                            </span>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-between p-2">
            <ngb-pagination [collectionSize]="totalItems" [(page)]="currentPage" [maxSize]="8"
                [pageSize]="config.pageSize" (pageChange)="onPageChange($event)">
            </ngb-pagination>
        </div>
    </div>
    <div *ngIf="loading"
        style="position: absolute;width: 100%;height: 100%;background-color: #2f3c4e25;top: 0;left: 0;border-radius: 8px;">
        <div class="loader" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
            <div class="line bg-secondary"></div>
            <div class="line bg-secondary"></div>
            <div class="line bg-secondary"></div>
            <div class="line bg-secondary"></div>
        </div>
    </div>
</div>