import { Routes } from '@angular/router';

export const content: Routes = [
  { path: 'dashboard', loadChildren: () => import('../../pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'shipments', loadChildren: () => import('../../pages/shipments/shipments.module').then(m => m.ShipmentsModule) },
  { path: 'presents', loadChildren: () => import('../../pages/presents/presents.module').then(m => m.PresentsModule) },
  { path: 'products', loadChildren: () => import('../../pages/products/products.module').then(m => m.ProductsModule) },
  { path: 'settings', loadChildren: () => import('../../pages/settings/settings.module').then(m => m.SettingsModule) },
  { path: 'customers', loadChildren: () => import('../../pages/customers/customers.module').then(m => m.CustomersModule) },
  { path: 'orders', loadChildren: () => import('../../pages/orders/orders.module').then(m => m.OrdersModule) },
  { path: 'files', loadChildren: () => import('../../pages/files/files.module').then(m => m.FilesModule) },
  { path: 'admin', loadChildren: () => import('../../pages/admin/admin.module').then(m => m.AdminModule) },
];


