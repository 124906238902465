import { Injectable } from '@angular/core';
declare var BrowserPrint: any;
declare var Zebra: any;

@Injectable({
  providedIn: 'root'
})
export class ZebraPrinterService {

  public devices: any[] = [];
  public selectedDevice: any = null;

  constructor() {
    BrowserPrint.getLocalDevices(device_list => {

      this.devices = device_list.printer;
      this.selectedDevice = device_list.printer[0];
    });
  }

  setPrinter(printer: any) {
    this.selectedDevice = printer;
  }

  print(label: string, callback: Function | undefined, error: Function | undefined) {
    this.selectedDevice.send(label, callback, error);
  }
}