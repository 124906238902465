import { HttpClient } from '@angular/common/http';
import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false
	public fullScreen = false;

	constructor(http: HttpClient, authService: AuthService) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}

		authService.firebaseUserData$.subscribe((user) => {
			{
				if (user) {
					var subscription = http.get<Menu[]>(environment.apiServer + '/v1/Menu').subscribe(res => {
						this.MENUITEMS = res;
						this.items.next(this.MENUITEMS)
						subscription.unsubscribe();
					})
				}
			}
		});
	}

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?: any) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
