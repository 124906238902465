import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SocialNetworks } from 'src/app/shared/models/social-networks';

@Component({
  selector: 'card-social-networks',
  templateUrl: './card-social-networks.component.html',
  styleUrls: ['./card-social-networks.component.scss']
})
export class CardSocialNetworksComponent {
  @Input() model!: SocialNetworks;
  @Output() modelChange = new EventEmitter<SocialNetworks>();

  updateModel(value: SocialNetworks) {

    this.modelChange.emit(value);
  }
}