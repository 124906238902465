<ng-content select="[card-header]"></ng-content>
<ng-content select="[card-body]"></ng-content>
<ng-content select="[card-footer]"></ng-content>



<div *ngIf="loading"
    style="position: absolute;width: 100%;height: 100%;background-color: #2f3c4e25;top: 0;left: 0;border-radius: 8px;">
    <div class="loader" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
        <div class="line bg-secondary"></div>
        <div class="line bg-secondary"></div>
        <div class="line bg-secondary"></div>
        <div class="line bg-secondary"></div>
    </div>
</div>