export const environment = {
  production: true,
  apiServer: 'https://api.shipperstudio.com',
};
export const firebaseConfig = {
  apiKey: "AIzaSyBiuJPKEzxc8Ueii-IXoC-GLtZb2mWkSh8",
  authDomain: "shipperstudio-com.firebaseapp.com",
  projectId: "shipperstudio-com",
  storageBucket: "shipperstudio-com.appspot.com",
  messagingSenderId: "748171135322",
  appId: "1:748171135322:web:eb57e54daa20a684668b63",
  measurementId: "G-PB46JMS7R9"
};
