import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { LoaderComponent } from "./components/loader/loader.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { ContentLayoutComponent } from "./components/layout/content-layout/content-layout.component";
import { FullLayoutComponent } from "./components/layout/full-layout/full-layout.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { BookmarkComponent } from "./components/bookmark/bookmark.component";
import { TranslateModule } from "@ngx-translate/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CustomizerComponent } from "./components/customizer/customizer.component";

// services
import { NavService } from "./services/nav.service";
import { CustomizerService } from "./services/customizer.service";
// Directives
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { FormGroupInputComponent } from './components/base/form-group-input/form-group-input.component';
import { CountryPipe } from './pipes/country.pipe';
import { EntetiesTableComponent } from './components/base/enteties-table/enteties-table.component';
import { CardAddressComponent } from './components/base/card-address/card-address.component';
import { CardContactDitalesComponent } from './components/base/card-contact-ditales/card-contact-ditales.component';
import { CardSocialNetworksComponent } from './components/base/card-social-networks/card-social-networks.component';
import { NgbdSortableHeader } from "./directives/NgbdSortableHeader";
import { CompanyRolePipe } from './pipes/company-role.pipe';
import { SystemRolePipe } from './pipes/system-role.pipe';
import { CardBodyDirective, CardComponent, CardFooterDirective, CardHeaderDirective } from './components/base/card/card.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    BookmarkComponent,
    ContentLayoutComponent,
    FullLayoutComponent,
    FeatherIconsComponent,
    BreadcrumbComponent,
    CustomizerComponent,
    FormGroupInputComponent,
    CountryPipe,
    EntetiesTableComponent,
    CardAddressComponent,
    CardContactDitalesComponent,
    CardSocialNetworksComponent,
    NgbdSortableHeader,
    CompanyRolePipe,
    SystemRolePipe,
    CardComponent,
    CardHeaderDirective,
    CardBodyDirective,
    CardFooterDirective,
    DateAgoPipe],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule,
    NgbModule],
  exports: [
    LoaderComponent,
    FeatherIconsComponent,
    TranslateModule,
    FormGroupInputComponent,
    CountryPipe,
    CardAddressComponent,
    CardSocialNetworksComponent,
    CardContactDitalesComponent,
    EntetiesTableComponent,
    CardComponent,
    CardHeaderDirective,
    CardBodyDirective,
    CardFooterDirective],
  providers: [NavService, CustomizerService],
})
export class SharedModule { }
