import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Observable, Subscriber } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../models/User';
//import { SignalrService } from './signalr.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userLoded: boolean = false;
  public userLoded_observers$: Subscriber<any>;
  public userLoded$ = new Observable((observer) => { this.userLoded_observers$ = observer; });

  firebaseUserData: any; // Save logged in user data
  public firebaseUserData_observers$: Subscriber<any>;
  public firebaseUserData$ = new Observable((observer) => { this.firebaseUserData_observers$ = observer; });

  userData: User; // Save logged in user data
  public userData_observers$: Subscriber<any>;
  public userData$ = new Observable((observer) => { this.userData_observers$ = observer; });

  constructor(
    //public signalRService: SignalrService,
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public http: HttpClient
  ) {

    this.firebaseUserData$ = new Observable((observer) => {
      {
        this.firebaseUserData_observers$ = observer;
        if (this.firebaseUserData != null)
          this.firebaseUserData_observers$.next(this.firebaseUserData);
      }
    });

    this.userLoded$ = new Observable((observer) => {
      {
        this.userLoded_observers$ = observer;
        if (this.userLoded != null)
          this.userLoded_observers$.next(this.userLoded);
      }
    });

    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.firebaseUserData = user;
        if (this.firebaseUserData_observers$ != null)
          this.firebaseUserData_observers$.next(this.firebaseUserData);
        this.getMe();
        localStorage.setItem('user', JSON.stringify(this.firebaseUserData));
        JSON.parse(localStorage.getItem('user')!);
      } else {
        localStorage.setItem('user', 'null');
        JSON.parse(localStorage.getItem('user')!);
      }
    });

  }
  // Sign in with email/password
  SignIn(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        //this.SetUserData(result.user);
        this.afAuth.authState.subscribe((user) => {
          if (user) {
            this.router.navigate(['shipments']);
          }
        });
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }
  // Sign up with email/password
  SignUp(email: string, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.SendVerificationMail();
        //this.SetUserData(result.user);
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }
  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        this.router.navigate(['verify-email-address']);
      });
  }
  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error);
      });
  }
  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null /*&& user.emailVerified !== false*/ ? true : false;
  }
  // Sign in with Google
  // GoogleAuth() {
  //   return this.AuthLogin(new auth.GoogleAuthProvider()).then((res: any) => {
  //     this.router.navigate(['dashboard']);
  //   });
  // }
  // Auth logic to run auth providers
  AuthLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.router.navigate(['shipments']);
        //this.SetUserData(result.user);
      })
      .catch((error) => {
        window.alert(error);
      });
  }
  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.userLoded = true;
      if (this.userLoded_observers$ != null)
        this.userLoded_observers$.next(this.userLoded);
      this.router.navigate(['authentication', 'login']);
    });
  }
  getMe() {
    var subscription = this.http.get<User>(environment.apiServer + '/v1/users/me').subscribe((data) => {
      if (data != null) {
        this.userData = data;
        this.userLoded = true;
        if (this.userData_observers$ != null)
          this.userData_observers$.next(this.userData);

        if (this.userLoded_observers$ != null)
          this.userLoded_observers$.next(this.userLoded);
      }
      subscription.unsubscribe();
    });
  }
}