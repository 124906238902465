import { Pipe, PipeTransform } from '@angular/core';
import { countries } from '../data/countries';

@Pipe({
  name: 'country'
})
export class CountryPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    var a = countries.find((item) => item.CountryCode == value);
    return a?.Name ?? value;
  }
}