export const countries = [
    {
        "CountryCode": "AD",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "ANDORRA",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "AE",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "UNITED ARAB EMIRATES",
        "ISOCurrencyCodeName": "AED - UAE Dirham",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "C - County"
    }, {
        "CountryCode": "AF",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "AFGHANISTAN",
        "ISOCurrencyCodeName": "USD - US Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "AG",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "ANTIGUA",
        "ISOCurrencyCodeName": "XCD - East Caribbean Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "AI",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "ANGUILLA",
        "ISOCurrencyCodeName": "XCD - East Caribbean Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "AL",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "ALBANIA",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "AM",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "ARMENIA",
        "ISOCurrencyCodeName": "AMD - Dran",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "C - County"
    }, {
        "CountryCode": "AO",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "ANGOLA",
        "ISOCurrencyCodeName": "AOA - Kwanza",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "AR",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "ARGENTINA",
        "ISOCurrencyCodeName": "ARS - Argentine Peso",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "AS",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "AMERICAN SAMOA",
        "ISOCurrencyCodeName": "USD - US Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "AT",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "AUSTRIA",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "AU",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "AUSTRALIA",
        "ISOCurrencyCodeName": "AUD - Australian Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "S - STATE"
    }, {
        "CountryCode": "AW",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "ARUBA",
        "ISOCurrencyCodeName": "AWG - Aruba Guilder",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "AZ",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "AZERBAIJAN",
        "ISOCurrencyCodeName": "AZN - Manat",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "C - County"
    }, {
        "CountryCode": "BA",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "BOSNIA AND HERZEGOVINA",
        "ISOCurrencyCodeName": "BAM - Convertible Marks",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "C - County"
    }, {
        "CountryCode": "BB",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "BARBADOS",
        "ISOCurrencyCodeName": "BBD - Barbadian Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "BD",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "BANGLADESH",
        "ISOCurrencyCodeName": "BDT - Taka",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "C - County"
    }, {
        "CountryCode": "BE",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "BELGIUM",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "BF",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "BURKINA FASO",
        "ISOCurrencyCodeName": "XOF - CFA Franc - West Africa",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "BG",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "BULGARIA",
        "ISOCurrencyCodeName": "BGN - Bulgarian Lev",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "BH",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "BAHRAIN",
        "ISOCurrencyCodeName": "BHD - Bahraini Dinar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "BI",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "BURUNDI",
        "ISOCurrencyCodeName": "BIF - Burundese Franc",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "BJ",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "BENIN",
        "ISOCurrencyCodeName": "XOF - CFA Franc - West Africa",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "BM",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "BERMUDA",
        "ISOCurrencyCodeName": "BMD - Bermudian Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "BN",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "BRUNEI",
        "ISOCurrencyCodeName": "BND - Brunei Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "C - County"
    }, {
        "CountryCode": "BO",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "BOLIVIA",
        "ISOCurrencyCodeName": "BOB - Boliviano",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "BR",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "BRAZIL",
        "ISOCurrencyCodeName": "BRL - Real",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "BS",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "BAHAMAS",
        "ISOCurrencyCodeName": "BSD - Bahamian Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "BT",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "BHUTAN",
        "ISOCurrencyCodeName": "BTN - Ngultrum",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "BW",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "BOTSWANA",
        "ISOCurrencyCodeName": "BWP - Pula",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "BY",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "BELARUS",
        "ISOCurrencyCodeName": "BYN - Belarussian Ruble",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "C - County"
    }, {
        "CountryCode": "BZ",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "BELIZE",
        "ISOCurrencyCodeName": "BZD - Belize Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "CA",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "CANADA",
        "ISOCurrencyCodeName": "CAD - Canadian Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "P - PROVINCE"
    }, {
        "CountryCode": "CD",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "CONGO, THE DEMOCRATIC REPUBLIC OF",
        "ISOCurrencyCodeName": "CDF - Franc Congolais",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "CF",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "CENTRAL AFRICAN REPUBLIC",
        "ISOCurrencyCodeName": "XAF - CFA Franc - Central Africa",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "CG",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "CONGO",
        "ISOCurrencyCodeName": "XAF - CFA Franc - Central Africa",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "CH",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "SWITZERLAND",
        "ISOCurrencyCodeName": "CHF - Swiss Franc",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "CI",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "COTE D IVOIRE",
        "ISOCurrencyCodeName": "XOF - CFA Franc - West Africa",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "CK",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "COOK ISLANDS",
        "ISOCurrencyCodeName": "NZD - New Zealand Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "CL",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "CHILE",
        "ISOCurrencyCodeName": "CLP - New Chile Peso",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "CM",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "CAMEROON",
        "ISOCurrencyCodeName": "XAF - CFA Franc - Central Africa",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "CN",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "CHINA, PEOPLES REPUBLIC",
        "ISOCurrencyCodeName": "CNY - Yuan (Ren Min Bi)",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "CO",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "COLOMBIA",
        "ISOCurrencyCodeName": "COP - Colombian Peso",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "CR",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "COSTA RICA",
        "ISOCurrencyCodeName": "CRC - Costa Rican Colon",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "CU",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "CUBA",
        "ISOCurrencyCodeName": "CUC - Peso Convertible",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "CV",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "CAPE VERDE",
        "ISOCurrencyCodeName": "CVE - Cape Verde Escudo",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "CY",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "CYPRUS",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "CZ",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "CZECH REPUBLIC, THE",
        "ISOCurrencyCodeName": "CZK - Czech Koruna",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "DE",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "GERMANY",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "DJ",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "DJIBOUTI",
        "ISOCurrencyCodeName": "DJF - Djibouti Franc",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "DK",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "DENMARK",
        "ISOCurrencyCodeName": "DKK - Danish Krone",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "DM",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "DOMINICA",
        "ISOCurrencyCodeName": "XCD - East Caribbean Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "DO",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "DOMINICAN REPUBLIC",
        "ISOCurrencyCodeName": "DOP - Dominican Republic Peso",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "DZ",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "ALGERIA",
        "ISOCurrencyCodeName": "DZD - Algerian Dinar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "EC",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "ECUADOR",
        "ISOCurrencyCodeName": "USD - US Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "EE",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "ESTONIA",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "EG",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "EGYPT",
        "ISOCurrencyCodeName": "EGP - Egyptian Pound",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "ER",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "ERITREA",
        "ISOCurrencyCodeName": "ERN - Nakfa",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "ES",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "SPAIN",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "ET",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "ETHIOPIA",
        "ISOCurrencyCodeName": "ETB - Birr",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "FI",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "FINLAND",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "FJ",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "FIJI",
        "ISOCurrencyCodeName": "FJD - Fijian Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "FK",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "FALKLAND ISLANDS",
        "ISOCurrencyCodeName": "GBP - Pound Sterling",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "FM",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "MICRONESIA, FEDERATED STATES OF",
        "ISOCurrencyCodeName": "USD - US Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "FO",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "FAROE ISLANDS",
        "ISOCurrencyCodeName": "DKK - Danish Krone",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "FR",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "FRANCE",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "GA",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "GABON",
        "ISOCurrencyCodeName": "XAF - CFA Franc - Central Africa",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "GB",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "UNITED KINGDOM",
        "ISOCurrencyCodeName": "GBP - Pound Sterling",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "C - County"
    }, {
        "CountryCode": "GD",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "GRENADA",
        "ISOCurrencyCodeName": "XCD - East Caribbean Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "GE",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "GEORGIA",
        "ISOCurrencyCodeName": "GEL - Georgian Lari",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "C - County"
    }, {
        "CountryCode": "GF",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "FRENCH GUYANA",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "GG",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "GUERNSEY",
        "ISOCurrencyCodeName": "GBP - Pound Sterling",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "GH",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "GHANA",
        "ISOCurrencyCodeName": "GHS - Cedi",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "GI",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "GIBRALTAR",
        "ISOCurrencyCodeName": "GBP - Pound Sterling",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "GL",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "GREENLAND",
        "ISOCurrencyCodeName": "DKK - Danish Krone",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "GM",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "GAMBIA",
        "ISOCurrencyCodeName": "GMD - Dalasi",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "GN",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "GUINEA REPUBLIC",
        "ISOCurrencyCodeName": "GNF - Guinea Franc",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "GP",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "GUADELOUPE",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "GQ",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "GUINEA-EQUATORIAL",
        "ISOCurrencyCodeName": "XAF - CFA Franc - Central Africa",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "GR",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "GREECE",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "GT",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "GUATEMALA",
        "ISOCurrencyCodeName": "GTQ - Quetzal",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "GU",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "GUAM",
        "ISOCurrencyCodeName": "USD - US Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "GW",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "GUINEA-BISSAU",
        "ISOCurrencyCodeName": "XOF - CFA Franc - West Africa",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "GY",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "GUYANA (BRITISH)",
        "ISOCurrencyCodeName": "GYD - Guyanan Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "HK",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "HONG KONG",
        "ISOCurrencyCodeName": "HKD - Hong Kong Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "HN",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "HONDURAS",
        "ISOCurrencyCodeName": "HNL - Lempira",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "HR",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "CROATIA",
        "ISOCurrencyCodeName": "HRK - Croatian Kuna",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "HT",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "HAITI",
        "ISOCurrencyCodeName": "HTG - Gourde",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "HU",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "HUNGARY",
        "ISOCurrencyCodeName": "HUF - Forint",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "IC",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "CANARY ISLANDS, THE",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "A"
    }, {
        "CountryCode": "ID",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "INDONESIA",
        "ISOCurrencyCodeName": "IDR - Rupiah",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "IE",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "IRELAND, REPUBLIC OF",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "C - County"
    }, {
        "CountryCode": "IL",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "ISRAEL",
        "ISOCurrencyCodeName": "ILS - New Israeli Shekel",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "IN",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "INDIA",
        "ISOCurrencyCodeName": "INR - Indian Rupee",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "C - County"
    }, {
        "CountryCode": "IQ",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "IRAQ",
        "ISOCurrencyCodeName": "USD - US Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "IR",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "IRAN (ISLAMIC REPUBLIC OF)",
        "ISOCurrencyCodeName": "IRR - Iranian Rial",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "IS",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "ICELAND",
        "ISOCurrencyCodeName": "ISK - Icelandic Krona",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "IT",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "ITALY",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "C - County"
    }, {
        "CountryCode": "JE",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "JERSEY",
        "ISOCurrencyCodeName": "GBP - Pound Sterling",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "JM",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "JAMAICA",
        "ISOCurrencyCodeName": "JMD - Jamaican Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "JO",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "JORDAN",
        "ISOCurrencyCodeName": "JOD - Jordanian Dinar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "JP",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "JAPAN",
        "ISOCurrencyCodeName": "JPY - Yen",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "S - STATE"
    }, {
        "CountryCode": "KE",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "KENYA",
        "ISOCurrencyCodeName": "KES - Kenyan Shilling",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "KG",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "KYRGYZSTAN",
        "ISOCurrencyCodeName": "KGS - Som",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "C - County"
    }, {
        "CountryCode": "KH",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "CAMBODIA",
        "ISOCurrencyCodeName": "KHR - Khmer Rial",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "KI",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "KIRIBATI",
        "ISOCurrencyCodeName": "AUD - Australian Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "KM",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "COMOROS",
        "ISOCurrencyCodeName": "KMF - Comoros Franc",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "KN",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "ST. KITTS",
        "ISOCurrencyCodeName": "XCD - East Caribbean Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "KP",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "KOREA, THE D.P.R OF (NORTH K.)",
        "ISOCurrencyCodeName": "KPW - North Korean Won",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "KR",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "KOREA, REPUBLIC OF (SOUTH K.)",
        "ISOCurrencyCodeName": "KRW - Won",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "KV",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "KOSOVO",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "KW",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "KUWAIT",
        "ISOCurrencyCodeName": "KWD - Kuwaiti Dinar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "KY",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "CAYMAN ISLANDS",
        "ISOCurrencyCodeName": "KYD - Cayman Islands Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "KZ",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "KAZAKHSTAN",
        "ISOCurrencyCodeName": "KZT - Tenge",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "C - County"
    }, {
        "CountryCode": "LA",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "LAO PEOPLES DEMOCRATIC REPUBLIC",
        "ISOCurrencyCodeName": "LAK - Kip",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "LB",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "LEBANON",
        "ISOCurrencyCodeName": "USD - US Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "LC",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "ST. LUCIA",
        "ISOCurrencyCodeName": "XCD - East Caribbean Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "LI",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "LIECHTENSTEIN",
        "ISOCurrencyCodeName": "CHF - Swiss Franc",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "LK",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "SRI LANKA",
        "ISOCurrencyCodeName": "LKR - Sri Lankan Rupee",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "LR",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "LIBERIA",
        "ISOCurrencyCodeName": "LRD - Liberian Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "LS",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "LESOTHO",
        "ISOCurrencyCodeName": "LSL - Loti",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "LT",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "LITHUANIA",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "LU",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "LUXEMBOURG",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "LV",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "LATVIA",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "LY",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "LIBYA",
        "ISOCurrencyCodeName": "LYD - Libyan Dinar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "MA",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "MOROCCO",
        "ISOCurrencyCodeName": "MAD - Moroccan Dirham",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "MC",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "MONACO",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "MD",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "MOLDOVA, REPUBLIC OF",
        "ISOCurrencyCodeName": "MDL - Leu",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "C - County"
    }, {
        "CountryCode": "ME",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "MONTENEGRO, REPUBLIC OF",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "MG",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "MADAGASCAR",
        "ISOCurrencyCodeName": "MGA - Ariary",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "MH",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "MARSHALL ISLANDS",
        "ISOCurrencyCodeName": "USD - US Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "MK",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "MACEDONIA, REPUBLIC OF",
        "ISOCurrencyCodeName": "MKD - Denar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "ML",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "MALI",
        "ISOCurrencyCodeName": "XOF - CFA Franc - West Africa",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "MM",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "MYANMAR",
        "ISOCurrencyCodeName": "MMK - Kyat",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "MN",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "MONGOLIA",
        "ISOCurrencyCodeName": "MNT - Tugrik",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "MO",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "MACAU",
        "ISOCurrencyCodeName": "MOP - Pataca",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "MP",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "COMMONWEALTH NO. MARIANA ISLANDS",
        "ISOCurrencyCodeName": "USD - US Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "MQ",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "MARTINIQUE",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "MR",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "MAURITANIA",
        "ISOCurrencyCodeName": "MRO - Ouguiya",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "MS",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "MONTSERRAT",
        "ISOCurrencyCodeName": "XCD - East Caribbean Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "MT",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "MALTA",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "MU",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "MAURITIUS",
        "ISOCurrencyCodeName": "MUR - Mauritius Rupee",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "MV",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "MALDIVES",
        "ISOCurrencyCodeName": "MVR - Rufiyaa",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "MW",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "MALAWI",
        "ISOCurrencyCodeName": "MWK - Kwacha",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "MX",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "MEXICO",
        "ISOCurrencyCodeName": "MXN - Mexican Nuevo Peso",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "MY",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "MALAYSIA",
        "ISOCurrencyCodeName": "MYR - Ringgit",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "MZ",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "MOZAMBIQUE",
        "ISOCurrencyCodeName": "MZN - Mozambique Metical",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "NA",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "NAMIBIA",
        "ISOCurrencyCodeName": "NAD - Namibian Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "NC",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "NEW CALEDONIA",
        "ISOCurrencyCodeName": "XPF - CFP Franc",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "NE",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "NIGER",
        "ISOCurrencyCodeName": "XOF - CFA Franc - West Africa",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "NG",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "NIGERIA",
        "ISOCurrencyCodeName": "NGN - Naira",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "NI",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "NICARAGUA",
        "ISOCurrencyCodeName": "NIO - Cordoba Oro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "NL",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "NETHERLANDS, THE",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "NO",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "NORWAY",
        "ISOCurrencyCodeName": "NOK - Norwegian Krone",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "NP",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "NEPAL",
        "ISOCurrencyCodeName": "NPR - Nepalese Rupee",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "NR",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "NAURU, REPUBLIC OF",
        "ISOCurrencyCodeName": "AUD - Australian Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "NU",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "NIUE",
        "ISOCurrencyCodeName": "NZD - New Zealand Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "NZ",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "NEW ZEALAND",
        "ISOCurrencyCodeName": "NZD - New Zealand Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "R"
    }, {
        "CountryCode": "OM",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "OMAN",
        "ISOCurrencyCodeName": "OMR - Omani Rial",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "PA",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "PANAMA",
        "ISOCurrencyCodeName": "USD - US Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "PE",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "PERU",
        "ISOCurrencyCodeName": "PEN - Nuevo Sol",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "PF",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "TAHITI",
        "ISOCurrencyCodeName": "XPF - CFP Franc",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "PG",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "PAPUA NEW GUINEA",
        "ISOCurrencyCodeName": "PGK - Kina",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "P - PROVINCE"
    }, {
        "CountryCode": "PH",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "PHILIPPINES, THE",
        "ISOCurrencyCodeName": "PHP - Phillipines Peso",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "C - County"
    }, {
        "CountryCode": "PK",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "PAKISTAN",
        "ISOCurrencyCodeName": "PKR - Pakistani Rupee",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "PL",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "POLAND",
        "ISOCurrencyCodeName": "PLN - Zloty",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "PR",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "PUERTO RICO",
        "ISOCurrencyCodeName": "USD - US Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "PT",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "PORTUGAL",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "PW",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "PALAU",
        "ISOCurrencyCodeName": "USD - US Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "PY",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "PARAGUAY",
        "ISOCurrencyCodeName": "PYG - Guarani",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "QA",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "QATAR",
        "ISOCurrencyCodeName": "QAR - Qatar Rial",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "RE",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "REUNION, ISLAND OF",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "RO",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "ROMANIA",
        "ISOCurrencyCodeName": "RON - Leu",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "RS",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "SERBIA, REPUBLIC OF",
        "ISOCurrencyCodeName": "RSD - Serbia, Dinars",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "RU",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "RUSSIAN FEDERATION, THE",
        "ISOCurrencyCodeName": "RUB - Russian Ruble",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "RW",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "RWANDA",
        "ISOCurrencyCodeName": "RWF - Rwanda Franc",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "SA",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "SAUDI ARABIA",
        "ISOCurrencyCodeName": "SAR - Saudi Riyal",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "SB",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "SOLOMON ISLANDS",
        "ISOCurrencyCodeName": "SBD - Solomon Islands Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "SC",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "SEYCHELLES",
        "ISOCurrencyCodeName": "SCR - Seychelles Rupee",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "SD",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "SUDAN",
        "ISOCurrencyCodeName": "SDG - Sudanese Pound",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "SE",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "SWEDEN",
        "ISOCurrencyCodeName": "SEK - Swedish Krona",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "C - County"
    }, {
        "CountryCode": "SG",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "SINGAPORE",
        "ISOCurrencyCodeName": "SGD - Singapore Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "S - STATE"
    }, {
        "CountryCode": "SH",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "SAINT HELENA",
        "ISOCurrencyCodeName": "SHP - St. Helena Pound",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "D - District"
    }, {
        "CountryCode": "SI",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "SLOVENIA",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "SK",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "SLOVAKIA",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "SL",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "SIERRA LEONE",
        "ISOCurrencyCodeName": "SLL - Leone",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "SM",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "EU",
        "Name": "SAN MARINO",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "SN",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "SENEGAL",
        "ISOCurrencyCodeName": "XOF - CFA Franc - West Africa",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "SO",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "SOMALIA",
        "ISOCurrencyCodeName": "SOS - Somali Shilling",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "SR",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "SURINAME",
        "ISOCurrencyCodeName": "SRD - Suriname Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "SS",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "SOUTH SUDAN",
        "ISOCurrencyCodeName": "SSP - South Sudanese pound",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "ST",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "SAO TOME AND PRINCIPE",
        "ISOCurrencyCodeName": "STD - Dobra",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "SV",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "EL SALVADOR",
        "ISOCurrencyCodeName": "USD - US Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "SY",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "SYRIA",
        "ISOCurrencyCodeName": "SYP - Syrian Pound",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "P - PROVINCE"
    }, {
        "CountryCode": "SZ",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "SWAZILAND",
        "ISOCurrencyCodeName": "SZL - Lilangeni",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "TC",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "TURKS AND CAICOS ISLANDS",
        "ISOCurrencyCodeName": "USD - US Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "TD",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "CHAD",
        "ISOCurrencyCodeName": "XAF - CFA Franc - Central Africa",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "TG",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "TOGO",
        "ISOCurrencyCodeName": "XOF - CFA Franc - West Africa",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "TH",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "THAILAND",
        "ISOCurrencyCodeName": "THB - Baht",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "TJ",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "TAJIKISTAN",
        "ISOCurrencyCodeName": "TJS - Somoni",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "C - County"
    }, {
        "CountryCode": "TL",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "TIMOR LESTE",
        "ISOCurrencyCodeName": "USD - US Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "TN",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "TUNISIA",
        "ISOCurrencyCodeName": "TND - Tunisian Dinar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "TO",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "TONGA",
        "ISOCurrencyCodeName": "TOP - Pa'anga",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "TR",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "TURKEY",
        "ISOCurrencyCodeName": "TRY - New Turkish Lira",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "TT",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "TRINIDAD AND TOBAGO",
        "ISOCurrencyCodeName": "TTD - Trinidad and Tobago Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "TV",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "TUVALU",
        "ISOCurrencyCodeName": "AUD - Australian Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "TW",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "TAIWAN",
        "ISOCurrencyCodeName": "TWD - New Taiwan Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "TZ",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "TANZANIA",
        "ISOCurrencyCodeName": "TZS - Tanzanian Shilling",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "UA",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "UKRAINE",
        "ISOCurrencyCodeName": "UAH - Hryvna",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "S - STATE"
    }, {
        "CountryCode": "UG",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "UGANDA",
        "ISOCurrencyCodeName": "USD - US Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "US",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "UNITED STATES OF AMERICA",
        "ISOCurrencyCodeName": "USD - US Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "S - STATE"
    }, {
        "CountryCode": "UY",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "URUGUAY",
        "ISOCurrencyCodeName": "UYU - Peso Uruguayo",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "UZ",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "UZBEKISTAN",
        "ISOCurrencyCodeName": "UZS - Sum",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "C - County"
    }, {
        "CountryCode": "VA",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "Not applicable",
        "Name": "VATICAN CITY STATE",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "VC",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "ST. VINCENT",
        "ISOCurrencyCodeName": "XCD - East Caribbean Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "VE",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "VENEZUELA",
        "ISOCurrencyCodeName": "VEF - Bolivar Fuerte",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "VG",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "VIRGIN ISLANDS (BRITISH)",
        "ISOCurrencyCodeName": "USD - US Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "VI",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "VIRGIN ISLANDS (US)",
        "ISOCurrencyCodeName": "USD - US Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "VN",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "VIETNAM",
        "ISOCurrencyCodeName": "VND - Dong",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "VU",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "VANUATU",
        "ISOCurrencyCodeName": "VUV - Vanuatu Vatu",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "WS",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "SAMOA",
        "ISOCurrencyCodeName": "WST - Tala",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "XB",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "BONAIRE",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "XC",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "CURACAO",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "XE",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "ST. EUSTATIUS",
        "ISOCurrencyCodeName": "ANG - Netherlands Antilles Guilder",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "XM",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "ST. MAARTEN",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "XN",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "NEVIS",
        "ISOCurrencyCodeName": "XCD - East Caribbean Dollar",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "S - Suburb",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "XS",
        "SupportedAsOrigin": "P",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "SOMALILAND, REP OF (NORTH SOMALIA)",
        "ISOCurrencyCodeName": "USD - US Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }, {
        "CountryCode": "XY",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AM",
        "Name": "ST. BARTHELEMY",
        "ISOCurrencyCodeName": "ANG - Netherlands Antilles Guilder",
        "MeasureWeight": "LB",
        "MeasureDimension": "IN",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "YE",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "YEMEN, REPUBLIC OF",
        "ISOCurrencyCodeName": "YER - Yemeni Riyal",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "YT",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "MAYOTTE",
        "ISOCurrencyCodeName": "EUR - Euro",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "ZA",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "SOUTH AFRICA",
        "ISOCurrencyCodeName": "ZAR - South African Rand",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "CP - City Postcode",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "ZM",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "ZAMBIA",
        "ISOCurrencyCodeName": "ZMW - Kwacha",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "N/A"
    }, {
        "CountryCode": "ZW",
        "SupportedAsOrigin": "Y",
        "RegionCodeInXMLServicesLevel": "AP",
        "Name": "ZIMBABWE",
        "ISOCurrencyCodeName": "USD - US Dollar",
        "MeasureWeight": "KG",
        "MeasureDimension": "CM",
        "LocationTypeCode": "C - City",
        "DivisionTypeCodeDescription": "O"
    }
];