import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContactDitales } from 'src/app/shared/models/contact-ditales';

@Component({
  selector: 'card-contact-ditales',
  templateUrl: './card-contact-ditales.component.html',
  styleUrls: ['./card-contact-ditales.component.scss']
})
export class CardContactDitalesComponent {
  @Input() model!: ContactDitales;
  @Output() modelChange = new EventEmitter<ContactDitales>();

  updateModel(value: ContactDitales) {

    this.modelChange.emit(value);
  }
}