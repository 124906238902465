import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule, HttpLoaderFactory } from "./shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { ToastrModule } from "ngx-toastr";
import { DecimalPipe } from "@angular/common";
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { environment, firebaseConfig } from '../environments/environment';

import { CookieService } from "ngx-cookie-service";
//import "hammerjs";
//import "mousetrap";
import { AuthService } from "./shared/services/auth.service";
import { OAuthInterceptor } from "./shared/services/oauth.interceptor";
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ZebraPrinterService } from "./shared/services/zebra-printer.service";
import { SignalrService } from "./shared/services/SignalR/signalr.service";


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    NgxDatatableModule

  ],
  providers: [
    CookieService,
    DecimalPipe,
    ZebraPrinterService,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OAuthInterceptor,
      multi: true
    },
    SignalrService
  ],

  bootstrap: [AppComponent],
})
export class AppModule {

  constructor(private auth: AuthService, private signalRService: SignalrService) {
    this.auth.userLoded$.subscribe((userLoded) => {
      if (userLoded) {
        this.signalRService.startConnection();
        //this.signalRService.StartListeners();
      }
      // else {
      //   this.signalRService.hubConnection.stop();
      // }
    });
  }

}