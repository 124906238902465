import { Subscriber, Observable } from "rxjs";

export class SignalRListenerObject {
    //public connection: HubConnection;
    public Messages$: Observable<any>;
    public Messages_observers$: Subscriber<any>;

    constructor() {
        this.Messages$ = new Observable(observer => this.Messages_observers$ = observer)
    }

    fireMassage(massage: any) {
        this.Messages_observers$.next(massage);
    }
}
