export enum CompanyRole {
    General = 0,
    Sales = 1,
    Packer = 2,
    Accounting = 3,
    Admin = 1000
}

export enum SystemRole {
    Customer = 0,
    Support = 1,
    General = 2,
    Admin = 16588965,
}