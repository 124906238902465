import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AddressLine } from 'src/app/shared/models/address-line';

@Component({
  selector: 'card-address',
  templateUrl: './card-address.component.html',
  styleUrls: ['./card-address.component.scss']
})
export class CardAddressComponent {
  @Input() model!: AddressLine;
  @Output() modelChange = new EventEmitter<AddressLine>();

  updateModel(value: AddressLine) {

    this.modelChange.emit(value);
  }
}