import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Observable, mergeMap } from "rxjs";

@Injectable()
export class OAuthInterceptor implements HttpInterceptor {

    constructor(private auth: AngularFireAuth) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.auth.idToken.pipe(
            mergeMap((token: any) => {
                if (token) {
                    request = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
                }
                return next.handle(request);
            }));
    }
}