import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { SignalRListenerObject } from './signalr-listener-object';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  private hubConnection: HubConnection;
  private Listeners: { [name: string]: SignalRListenerObject } = {};

  constructor(private auth: AuthService) {
  }
  public startConnection = () => {
    if (this.hubConnection == null && this.auth.firebaseUserData != null) {
      const url = environment.apiServer + "/hub?access_token=" + this.auth.firebaseUserData.multiFactor.user.accessToken;

      this.hubConnection = new HubConnectionBuilder()
        .withUrl(url)
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Debug)
        .build();

      this.hubConnection
        .start()
        .then(() => console.log('Connection started'))
        .catch(err => console.log('Error while starting connection: ' + err));

      this.StartListener("notification");
    }
  }

  public StartListener(methodName: string) {
    this.AddSignalRListenerObject(methodName);
    this.hubConnection.on(methodName, (data) => this.Listeners[methodName].fireMassage(data));
  }

  // public StartListeners = () => {
  //   this.AddSignalRListenerObject('notification');
  //   this.AddSignalRListenerObject('command');


  //   this.hubConnection.on('notification', (data) => {
  //     this.Listeners['notification'].fireMassage(data);
  //   });

  //   this.hubConnection.on('command', (data) => {
  //     switch (data.command) {
  //       case "kill":
  //         //this.store.dispatch(new LogoutUserAction());
  //         break;
  //       default:
  //     }
  //     //this.Listeners['command'].fireMassage(data);
  //   });
  // }

  AddSignalRListenerObject(methodName: string) {
    if (this.Listeners[methodName] == null)
      this.Listeners[methodName] = new SignalRListenerObject();
  }

  GetMassagesObservable(methodName: string): Observable<any> {
    if (this.Listeners[methodName] == null)
      this.AddSignalRListenerObject(methodName);

    return this.Listeners[methodName].Messages$;
  }
}
