<div class="card">
    <div class="card-header">
        <h4 class="card-title mb-0">Social Networks</h4>
        <div class="card-options"><a class="card-options-collapse" [routerLink]="[]" data-bs-toggle="card-collapse"><i
                    class="fe fe-chevron-up"></i></a><a class="card-options-remove" [routerLink]="[]"
                data-bs-toggle="card-remove"><i class="fe fe-x"></i></a></div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-md-4">
                <form-group-input type="text" label="facebook" [(model)]="model.facebook"></form-group-input>
            </div>
            <div class="col-md-4">
                <form-group-input type="text" label="twitter" [(model)]="model.twitter"></form-group-input>
            </div>
            <div class="col-md-4">
                <form-group-input type="text" label="instagram" [(model)]="model.instagram"></form-group-input>
            </div>
            <div class="col-md-4">
                <form-group-input type="text" label="linkedin" [(model)]="model.linkedin"></form-group-input>
            </div>
        </div>
    </div>
</div>