<div class="card">
    <div class="card-header">
        <h4 class="card-title mb-0">Address</h4>
        <div class="card-options"><a class="card-options-collapse" [routerLink]="[]" data-bs-toggle="card-collapse"><i
                    class="fe fe-chevron-up"></i></a><a class="card-options-remove" [routerLink]="[]"
                data-bs-toggle="card-remove"><i class="fe fe-x"></i></a></div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-md-4">
                <form-group-input type="text" label="country" [(model)]="model.country"></form-group-input>
            </div>
            <div class="col-md-4">
                <form-group-input type="text" label="city" [(model)]="model.city"></form-group-input>
            </div>
            <div class="col-md-4">
                <form-group-input type="text" label="address" [(model)]="model.address"></form-group-input>
            </div>
            <div class="col-md-4">
                <form-group-input type="text" label="zipCode" [(model)]="model.zipCode"></form-group-input>
            </div>
            <div class="col-md-4">
                <form-group-input type="text" label="state" [(model)]="model.state"></form-group-input>
            </div>
        </div>
    </div>
</div>