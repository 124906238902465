import { Pipe, PipeTransform } from '@angular/core';
import { CompanyRole } from '../models/enums';

@Pipe({
  name: 'companyRole'
})
export class CompanyRolePipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    return CompanyRole[value];
  }
}