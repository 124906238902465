import { Pipe, PipeTransform } from '@angular/core';
import { SystemRole } from '../models/enums';

@Pipe({
  name: 'systemRole'
})
export class SystemRolePipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    return SystemRole[value];
  }
}