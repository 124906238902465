import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'form-group-input',
  templateUrl: './form-group-input.component.html',
  styleUrls: ['./form-group-input.component.scss']
})
export class FormGroupInputComponent {

  @Input() model!: any;
  @Output() modelChange = new EventEmitter<any>();

  @Input() type!: string;
  @Input() label!: string;
  @Input() disabled!: boolean;

  updateModel(value: any) {

    this.modelChange.emit(value == "" ? null : value);
  }

}
