import { Component, Directive, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @HostBinding('class.card') get cardClass() { return true; }

  @Input() loading: boolean = false;
}


@Directive({
  selector: '[card-header]'
})
export class CardHeaderDirective {
  @HostBinding('class.card-header') get cardHeaderClass() { return true; }
}

@Directive({
  selector: '[card-body]'
})
export class CardBodyDirective {
  @HostBinding('class.card-body') get cardBodyClass() { return true; }
}

@Directive({
  selector: '[card-footer]'
})
export class CardFooterDirective {
  @HostBinding('class.card-footer') get cardFooterClass() { return true; }
}

