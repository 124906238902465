import { Component, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { NgbdSortableHeader, SortColumn, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';

export interface TableColumn {
  label: string;
  key: string;
  type?: string;
  sortColumn?: string;
  func?: Function;
  buttonText?: string;
  iconClass?: string;
}

export interface TableConfig {
  columns: TableColumn[];
  pageSize: number;
}

@Component({
  selector: 'enteties-table',
  templateUrl: './enteties-table.component.html',
  styleUrls: ['./enteties-table.component.scss']
})
export class EntetiesTableComponent implements OnChanges {
  selecteditems: any[];

  currentPage: number = 1;

  @Input() config: TableConfig;
  @Input() tableData: any[];
  @Input() totalItems: number;
  @Input() loading: boolean = false;
  @Input() selectable: boolean = false;

  @Output() pageChange = new EventEmitter<number>();
  @Output() rowClicked = new EventEmitter<any>();
  @Output() onSort = new EventEmitter<SortEvent>();
  @Output() itemChecked = new EventEmitter<any[]>();

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  //handle input cange event
  ngOnChanges(changes: SimpleChanges): void {

    if (changes['tableData'] != null)
      this.selecteditems = [];
  }

  checkItem(item: any) {
    if (this.selecteditems == null)
      this.selecteditems = [];
    if (this.selecteditems.includes(item))
      this.selecteditems.splice(this.selecteditems.indexOf(item), 1);
    else
      this.selecteditems.push(item);
    this.itemChecked.emit(this.selecteditems);
  }
  checkAll() {
    if (this.selecteditems == null)
      this.selecteditems = [];
    if (this.selecteditems.length == this.tableData.length)
      this.selecteditems = [];
    else
      this.selecteditems = this.tableData;
    this.itemChecked.emit(this.selecteditems);
  }
  isSelected(item: any) {
    if (this.selecteditems == null)
      this.selecteditems = [];
    return this.selecteditems.includes(item);
  }
  isSelectedAll() {
    if (this.selecteditems == null)
      this.selecteditems = [];
    return this.tableData != null ? this.selecteditems.length == this.tableData.length : false;
  }

  getNestedProperty(item: any, key: string): any {
    if (!item || !key) {
      return undefined;
    }

    if (key.includes('.')) {
      const keys = key.split('.');
      let value = item;

      for (const k of keys) {
        value = value[k];
        if (value === undefined) {
          break;
        }
      }

      return value;
    } else if (key.includes('[') && key.includes(']')) {
      const start = key.indexOf('[');
      const end = key.indexOf(']');
      const objKey = key.substring(0, start);
      const index = key.substring(start + 1, end).replace(/['"]+/g, '');
      return item[objKey] != null ? item[objKey][index] : undefined;
    } else {
      return item[key];
    }
  }

  clicked(item: any): void {
    if (this.rowClicked != null) {
      this.rowClicked.emit(item);
    }
  }

  onPageChange(event: number): void {
    this.currentPage = event;
    if (this.pageChange != null) {
      this.pageChange.emit(event);
    }
  }

  onSortClick({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    if (this.onSort != null) {
      this.onSort.emit({ column, direction });
    }

    // this.service.sortColumn = column;
    // this.service.sortDirection = direction;

  }

  buttonClicked(column: TableColumn, item: any) {
    if (column.func != null)
      column.func(item);
  }
}
