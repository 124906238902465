import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { Notification } from './notification';
import { SignalrService } from '../SignalR/signalr.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  notifications: Notification[] = [];
  public notifications_observers$: Subscriber<Notification[]>;
  public notifications$ = new Observable<Notification[]>((observer) => { this.notifications_observers$ = observer; });

  constructor(public SignalrService: SignalrService) {

    SignalrService.GetMassagesObservable("notification").subscribe((data) => {
      this.notifications.push(data);
      this.notifications_observers$.next(this.notifications);
    });
    //     let notifications: Notification[] = [
    //   { title: 'Your order ready for Ship..!', subject: 'Lorem ipsum dolor sit amet, consectetuer.', icon: 'shopping-bag', iconClass: "shopping-color", time: new Date() },
    //   { title: 'Download Complete', subject: 'Lorem ipsum dolor sit amet, consectetuer.', icon: 'download', iconClass: "download-color font-success", time: new Date(), textClass: 'txt-success' },
    //   { title: '250 MB trush files', subject: 'Lorem ipsum dolor sit amet, consectetuer.', icon: 'alert-circle', iconClass: "alert-color font-danger", time: new Date(), textClass: 'txt-danger' },
    // ];
  }

  loadNotifications() {
    this.notifications_observers$.next(this.notifications);
  }

  markAsRead(notification: Notification) {
    this.notifications.splice(this.notifications.indexOf(notification), 1);
    this.notifications_observers$.next(this.notifications);
  };

}
